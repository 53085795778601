import '../css/page.scss';

import 'swiper/swiper-bundle.css';


import $ from 'jquery'


import Swiper from 'swiper/bundle';


$(function () {
    console.log('ancla');
    
    var bannerHome = new Swiper('.banner-home .swiper-container', {
        slidesPerView: 1,
        navigation: {
            nextEl: '.banner-home .swiper-button-next',
            prevEl: '.banner-home .swiper-button-prev',
        },
        pagination: {
            el: '.banner-home .swiper-pagination',
            type: 'bullets',
        },
        autoplay: {
            delay: 5000,
        },
        loop: true
    });


    $('#searchbus').click(function (){
        $('#busquedageneral').submit();
    })

     
    $('.grid-productos').each(function(index){
        $(this).addClass("instance-" + index);
        var indexS = ".instance-" + index;
        var productos= new Swiper(indexS + ' .swiper-container', {
           loop: true,
           navigation: {
             nextEl: indexS + " .swiper-button-next",
             prevEl:  indexS + " .swiper-button-prev",
           },
           breakpoints: {
                320: {
                slidesPerView: 2,
                spaceBetween: 10
                },
                812: {
                slidesPerView: 4,
                spaceBetween: 20
                }
            }
        });
    });

    var productoGal = new Swiper('.prodInner .swiper-container', {
        slidesPerView: 1,
        navigation: {
            nextEl: '.prodInner .swiper-button-next',
            prevEl: '.prodInner .swiper-button-prev',
        },
        pagination: {
            el: '.prodInner .swiper-pagination',
            type: 'bullets',
        },
        autoplay: {
            delay: 5000,
        },
        loop: true
    });
    
    


  /*   $('.producto').each(function(index){
        $(this).addClass("producto-instance-" + index);
        var indexS = ".producto-instance-" + index;
        var productosInt= new Swiper(indexS + ' .swiper-container', {
            slidesPerView: 1,
            spaceBetween: 0,
            navigation: {
                nextEl: indexS + " .swiper-button-next",
                prevEl:  indexS + " .swiper-button-prev",
            }
        });
    }); */


});

